<template>
  <div class="little-tag" v-if="text">
    <div  class="ant-tag" v-for="(item ,index) in text.split(',')" :key="index">
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  name: "little-tag",
  props: {
    text: String,

  },
  mounted() {

  },
}
</script>

<style scoped>
.little-tag {
  height: 20px;
  width: 100%;
}

.ant-tag {
  font-feature-settings: "tnum", "tnum";
  display: inline-block;
  height: auto;
  margin: 0 8px 0 0;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all .3s;
}
</style>