<template>
  <div class="my-tag">
    <i :class="class_name"></i>
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "my-tag",
  props: ["text", "class_name"]
}
</script>

<style scoped>
.my-tag {
  width: auto;
  max-width: 200px;
  min-width: 100px;
  border-radius: 20px;
  color: rgba(33, 40, 53, .75);
  line-height: 30px;
  height: 30px;
  padding: 5px;
  text-align: center;
  background-color: rgba(0, 10, 32, .05);
  white-space: nowrap;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 550;
  margin: 0 10px;
}
</style>