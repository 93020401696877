import service from "@/utils/request";

/**
 * 根据用户获取所有的试卷信息
 * @returns {*}
 * @param userId
 */
export function getTestByUserId(userId,current) {
    return service(
        {
            url: 'test/getTestByUserId',
            method: 'post',
            params: {
                userId,current
            }
        })
}

/**
 *
 * @param  paper
 * @returns {*}
 */
export function addTest(paper) {
    return service(
        {
            url: 'test/addTest',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: paper
        })
}

/**
 * 更新试题信息
 * @param paper
 * @returns {*}
 */
export function updateTest(paper) {
    return service(
        {
            url: 'test/updateTest',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: paper
        })
}

/**
 * 删除试题信息
 * @param paper
 * @returns {*}
 */
export function deleteTest(id) {
    return service(
        {
            url: 'test/deleteTest',
            method: 'post',
            params: {
                id
            }
        })
}

/**
 * 获取首页全部试卷
 * @param current
 * @returns {AxiosPromise}
 */
export function getTest(current) {
    return service(
        {
            url: 'test/selectAllTest',
            method: 'post',
            params: {
                current
            }
        })
}

/**
 * 根据试卷id 来获取试卷的所有信息
 * @param id
 * @returns {AxiosPromise}
 */
export function getTestById(id) {
    return service(
        {
            url: 'test/getTestById',
            method: 'post',
            params: {
                id
            }
        })
}


