<template>
  <div class="test-content">
    <el-row class="test-row">
      <el-row class="tags">
        <el-col :span="3" v-for="(item,index) in tags" :key="index">
          <my-tag :text="item.text" :class_name="item.class_name"></my-tag>
        </el-col>
      </el-row>
      <el-table
          :data="test.records"
          stripe
          @row-click="rowClick"
          style="width: 100%">
        <el-table-column
            prop="name"
            label="题目名称"
            width="180">
        </el-table-column>
        <el-table-column
            prop="introduce"
            label="试卷介绍"
            width="180">
        </el-table-column>
        <el-table-column
            prop="type"
            label="分类">
          <template slot-scope="scope">
            {{ getTag(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column
            prop="type"
            label="标签信息">
          <template slot-scope="scope">
            <little-tag :text="scope.row.tags"></little-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="score"
            label="试卷分值">
        </el-table-column>
        <el-table-column
            prop="gmtCreate"
            label="创建时间">
        </el-table-column>
      </el-table>
      <el-row class="bottom">
        <el-col :span="10">
          <el-pagination
              background
              :current-page="test.current"
              @current-change="handleChange"
              layout="prev, pager, next"
              :total="test.pages">
          </el-pagination>
        </el-col>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import {getTest} from "@/api/test";
import {options} from "@/config/global";
import MyTag from "@/components/tags/my-tag";
import LittleTag from "@/components/tags/little-tag";

export default {
  name: "Test",
  components: {LittleTag, MyTag},
  data() {
    return {
      test: {},
      options,
      tags: [
        {
          text: "全部算法",
          class_name: "fa fa-database"
        },
        {
          text: "全部算法",
          class_name: "fa fa-database"
        },
        {
          text: "全部算法",
          class_name: "fa fa-database"
        },
        {
          text: "全部算法",
          class_name: "fa fa-database"
        }
      ]
    }
  },
  mounted() {
    this.handleChange(1);
  },
  methods: {
    // 获取首页的试卷全部信息
    handleChange(number) {
      getTest(number).then(result => {
        this.test = result.data.test;
      }).catch(error => {
        this.$message({
          message: error.message,
          type: "error",
          duration: 2 * 1000
        })
      })
    },
    getTag(index) {
      return this.options[index].label;
    },
    rowClick(row, column, event) {
      console.log(row)
      this.$router.push(
          {
            path: "/paper/showTest",
            query: {
              id: row.id,
              isShow: 0
            }
          }
      )
    },
    // jump(id) {
    //   this.$router.push(
    //       {
    //         path: "/paper/showTest",
    //         query: {
    //           id,
    //           isShow: 0
    //         }
    //       }
    //   )
    // }
  }
}
</script>

<style scoped>
/*总体布局*/
.test-content {
  margin: 0 auto !important;
  padding: 20px;
  background-color: #fff;
}
.left {

  margin: 0 !important;
}

.tags {
  height: 40px;
  margin: 10px auto;
  width: 100%;
}

.bottom {
  margin-top: 20px;
}
.card div {
  margin-bottom: 0.625rem;
}

/* 右边的类*/
.right {
  background-color: #fff;
  margin-left: 20px;
}
</style>
